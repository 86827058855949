<template>
  <el-dialog
    title="拟任公示--批量"
    :visible.sync="show"
    class="increase-publicity"
    :append-to-body="true"
    :close-on-press-escape="false"
    :close-on-click-modal="false"
    width="80%"
    custom-class
  >
    <el-table
      ref="table"
      align="center"
      v-loading="table.loading"
      :data="table.data"
      default-expand-all
      row-key="id"
      height="95%"
      :header-cell-style="{'text-align':'center','background-color':'#FAFAFA'}"
      :cell-style="{'text-align':'center'}"
      @selection-change="handleSelectionChange"
      @cell-click="selectOrCancle"
    >
      <el-table-column type="selection"></el-table-column>
      <el-table-column type="index" label="序号"></el-table-column>
      <el-table-column prop="nd" label="年度"></el-table-column>
      <el-table-column prop="sfzhm" label="身份证号码" width="180"></el-table-column>
      <el-table-column prop="xm" label="姓名"></el-table-column>
      <el-table-column prop="xb" label="性别" width="50"></el-table-column>
      <el-table-column prop="bmfs_a" label="报名方式"></el-table-column>
      <el-table-column prop="nrgs_a" label="拟任公示状态" width="120"></el-table-column>
      <el-table-column prop="csrq" label="出生日期">
        <template slot-scope="scope">
          <span v-if="scope.row.csrq">{{scope.row.csrq}}</span>
          <span v-else>{{scope.row.sfzhm|csrq}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="lxfs" label="联系方式"></el-table-column>
      <el-table-column prop="cjzd" label="常住地址" width="300"></el-table-column>
    </el-table>
    <div class="footer">
      <el-button type="primary" size="small" @click="pass" :loading="pass_loading">批量通过拟任公示</el-button>
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="table.query.page"
        :page-sizes="[10, 20, 50, 100]"
        :page-size="table.query.size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="table.total"
      ></el-pagination>
    </div>
  </el-dialog>
</template>

<script>
import Appointment from '@/api/increase/appointment';
import numberToString from '@/plugin/numberToString';
export default {
  name: 'checklot',
  props: {
    options: Object,
  },
  components: {},
  data() {
    return {
      show: false,
      table: {
        data: [],
        query: {
          keyword: '',
          page: 1,
          size: 100,
          nrgs: 1,
        },
        loading: false,
        total: 0,
        selected: [],
      },
      pass_loading: false,
    };
  },
  methods: {
    // 切换单元格的选中状态
    selectOrCancle(data) {
      this.$refs.table.toggleRowSelection(data);
    },
    // 批量通过
    pass() {
      if (this.table.selected.length === 0) {
        this.$alert('请勾选要通过的拟任公示人员名单');
        return;
      }
      this.pass_loading = true;
      let sfzhm = this.table.selected.map((item) => {
        console.log(item.sfzhm);
        return item.sfzhm;
      });
      Appointment.publicView({
        ok: 1,
        sfzhm,
      })
        .then(() => {
          this.$message.closeAll();
          this.$message.success('审核成功');
          this.$emit('getList');
          return this.reset();
        })
        .finally(() => {
          this.pass_loading = false;
        });
    },
    toggle(show) {
      if (!show) {
        this.$emit('getList');
        this.show = false;
        this.$emit('changeShow', false);
      } else {
        this.show = true;
        return this.getList();
      }
    },
    handleSelectionChange(data) {
      this.table.selected = data;
      console.log(this.table.selected);
    },
    handleSizeChange(data) {
      this.table.query.size = data;
      this.page = 1;
      return this.getList();
    },
    // 当前页数变化
    handleCurrentChange(data) {
      if (data === this.table.query.page) {
        return;
      } else {
        this.table.query.page = data;
        return this.getList();
      }
    },
    reset() {
      this.table.query.page = 1;
      this.table.query.size = 100;
      this.table.query.keyword = '';
      this.table.query.nrgs = 1;
      return this.getList();
    },
    getList() {
      this.table.loading = true;
      Appointment.list(this.table.query)
        .then((res) => {
          // 将审查状态由数字 变为可读的中文
          let data = numberToString(res.items, 'nrgs', this.options.nrgs.children);
          data = numberToString(res.items, 'bmfs', this.options.bmfs.children);
          this.table.data = data;
          this.table.total = res.total;
        })
        .finally(() => {
          this.table.loading = false;
        });
    },
  },
  mounted() {},
  created() {},
  computed: {},
  watch: {},
};
</script>

<style scoped lang="scss">
.increase-publicity {
  .footer {
    width: 100%;
    padding: 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    .el-pagination {
      padding: 10px;
      box-sizing: border-box;
      width: 70%;
    }
    .el-button {
      padding-top: 10px;
      padding-bottom: 10px;
      margin-right: 50px;
    }
  }
}
::v-deep .el-dialog {
  margin: 5vh auto 0 auto !important;
}
::v-deep .el-dialog__body {
  height: 80vh;
}
</style>
